<template>
	<div class="login">
		<el-card class="cardform" shadow="never">

			<el-form>

				<el-input placeholder="账号" v-model="username">
					<i slot="prefix" class="el-input__icon el-icon-user"></i>
				</el-input>
				<el-input placeholder="密码" v-model="password" :show-password="true" style="margin-top: 20px;">
					<i slot="prefix" class="el-input__icon el-icon-lock"></i>
				</el-input>
				<el-input placeholder="验证码" v-model="captcha"  style="margin-top: 20px;">
					<i slot="prefix" class="el-input__icon el-icon-lock"></i>
				</el-input>

				<el-image style="width: 100%; height: 50px;margin-top: 20px;" :src="url" @click="getcode"></el-image>

				<el-button :loading="loading" type="primary" style="width:100%;margin-top:20px;" @click.native.prevent="handleLogin">登录</el-button>
			</el-form>
		</el-card>
	</div>
</template>

<script>
	import axios from "axios";

	export default {
		data() {
			return {
				username: '',
				password: '',
				loading: false,
				captcha: '',
				url: '',
				checkKey: ''
			}

		},

		methods: {
			// 点击登录
			handleLogin() {
				const that = this;
				that.api('/admin/login', {
					username: that.username,
					password: that.password,
					captcha: that.captcha,
					checkKey: that.checkKey
				}).then(res => {
					if(res.code==200){
                        localStorage.setItem('userId',res.data.adminId)
                        localStorage.setItem('loginUserRealName',res.data.adminUsername)
						that.$router.replace({name:'memberlist'})
					}else if(res.code==911){
						that.getcode();
					}
					
				})


			},
			// 获取验证码
			getcode() {
				const that = this;

				that.checkKey = that.$utils.getstring(5)

				var apiurl = that.$utils.apiurl + '/captcha.jpg?uuid=';
				axios.get(apiurl + that.checkKey)
					.then((res) => {
						that.url = res.data.data
					})
					.catch((error) => {
						console.log(error); //异常
					});

			}

		},
		created() {
			const that = this;
			window.document.title = '妇幼后台管理';
			that.getcode();
		}
	}
</script>

<style scoped lang="less">
	.login {
		min-height: 100%;
		width: 100%;
		overflow: hidden;
		background-image: url('../../assets/images/loginbg.jpg');
		background-size: cover;
		background-position: center;
		position: fixed;
		// background-size: 100% 100%;

		.cardform {
			width: 300px;

			position: absolute;
			right: 160px;
			top: 50%;
			transform: translateY(-60%);

		}


	}
</style>
